import { Container} from 'react-bootstrap';
import NewsItems from '../NewsItems';
import ArticleItems from '../ArticleItems';
import Top      from "../Top2";
import React from 'react';

import {Sida,Rubrik,Stycke, Artickel, ContentWrapper} from "../ContentStyles";

const FormsInit = () => {
  return (
    <div>
    <ContentWrapper>
    <Container>
    <Sida>
      <Artickel id="DomStart">
          <Rubrik>FormsInit!</Rubrik>
          <Stycke>
            Test.<br /><br />


          </Stycke>
      </Artickel>
    </Sida>    
    </Container>
    </ContentWrapper>
    </div>
  );
};

export default FormsInit;