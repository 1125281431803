import { Container, Button,Form, Alert,Table} from 'react-bootstrap';
import {Sida,Rubrik,ContentWrapper,Stycke,StyckeCompact} from "./ContentStyles";
import SpinnerX from './SpinnerX';
import {Pageheight} from "../index";
import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FaFilePdf, FaCheck, FaEuroSign, FaShare, FaEdit} from 'react-icons/fa';

class MyComponent extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
          data: null,
          isLoading: false,
          error: null,
          resultVariant :"success",
          resultVisible:false,
          resultText :'N/A',
          spinnerHidden:false,
          itemGUID:null,
          cloudService:null
        }
    }

    CheckAccessToken;

    handleSubmit = (e) =>{
        e.preventDefault();
        this.setState({ resultVariant :'warning', resultVisible:true, resultText: 'You need elevated permissions to create new service!', spinnerHidden:true  });
    }     

    componentDidMount() {
        this.getData(window.location.pathname.replace('/CloudService/',''));
    }

    getData = (ItemGUID) =>{
        fetch('/backend/cloudServices/'+ItemGUID, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'accessToken':localStorage.getItem('access_token'),
        },
        }).then(response => response.json())
        .then(data => {
            if (data.errorsException===false) {
                this.setState({ resultVariant :'success', resultVisible:false, resultText: data.resultText, spinnerHidden:true, cloudService:data.cloudService });
            } else {
                this.setState({ resultVariant :'warning', resultVisible:true, resultText: data.resultText, spinnerHidden:true  });
            }
        })
        .catch(error => {
            this.setState({ resultVariant :'warning', resultVisible:true, resultText: 'Ett fel uppstod!', spinnerHidden:true  });
            //this.setState({   });
        });
    }  

    render() {
        if (this.state.cloudService !== null) {
            return (
                <ContentWrapper>
                    <Container>
                        <Sida style={{minHeight:Pageheight(400)}}>
                        <Rubrik>CloudServices</Rubrik>
                        {this.state.cloudService.description}
                    </Sida>
                    </Container>
                </ContentWrapper>
            );
        } else {
            return (
                <ContentWrapper>
                    <Container>
                        <Sida style={{minHeight:Pageheight(400)}}>
                        <Rubrik>Loading CloudService Details</Rubrik>
                        <SpinnerX  />
                    </Sida>
                    </Container>
                </ContentWrapper>
            );        
        }
    }
}

export default MyComponent;