import { useState, useEffect, useRef } from 'react';
import ClipLoader from "react-spinners/PacmanLoader";
import { Container, Button,Form, Alert, Modal, Table} from 'react-bootstrap';
import {Sida,Rubrik,ContentWrapper,Stycke,StyckeCompact} from "../pages/ContentStyles";

export default function useGetBackend(url) {
    const [data, setData] = useState([]);
    const [loading, setLoadning] = useState(true);

    const [resultVariant, setResultVariant] = useState(null);
    const [resultText,    setResultText]    = useState(null);
    const [resultVisible, setResultVisible] = useState(null);
    const [resultSuccess, setResultSuccess] = useState(true);

    var queryParam = useRef("");

    function Refresh(pageIndex=0, pageSize=25) {
        console.log("pageIndex:"+pageIndex);
        console.log("pageSize:"+pageSize);
        init(pageIndex, pageSize);
    }

    function Seek(QueryParam) {
        queryParam.current = QueryParam;
        init();
    }

    async function init(pageIndex=0, pageSize=25) {
        //setLoadning(true);
        try {
            // const response = await fetch(url + queryParam.current, {
            var query = queryParam.current;
            if (query.includes('?') || url.includes('?')) {
                query=query+"&"+ "pageSize="+pageSize+"&pageIndex="+pageIndex;
            } else {
                query="?pageSize="+pageSize+"&pageIndex="+pageIndex;
            }

            const response = await fetch(url + query, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'accessToken':localStorage.getItem('access_token'),
                },
            });

            if (response.status === 401) {
                window.location.href = "/login";                    
            }

            if (response.ok) {
                const json = await response.json();
                setResultVariant('success');
                setResultVisible(false);
                setResultText('');
                setData(json);
            } else {
                setResultVariant('warning');
                setResultVisible(true);
                setResultText('Ett fel uppstod!!');

                throw response;
            }
            
        }
        catch (e) {
            setResultVariant('warning');
            setResultVisible(true);
            setResultText('Ett fel uppstod!!!');
            setResultSuccess(false);
        }
        finally {
            setLoadning(false);
        }
    }

    useEffect(() => {init()}, [url]); 

    return { resultVariant, resultVisible, resultText, resultSuccess, loading, data, Refresh, Seek }; 
}

export async function CallAPI(url, method = 'GET', body = {}) {
    var resultVariant, resultVisible, resultText="...";
    var jsondata;
    try {
        
        const response =  await fetch(url, {
            method: method,
            headers: {
                'Content-Type': 'application/json',
                'accessToken':localStorage.getItem('access_token'),
            },            
            body: body
        });

        if (response.ok) {
            jsondata =  await response.json();
            
            resultVisible=true;
            resultText=jsondata.resultText;

            if (jsondata.errorsException) {
                resultVariant ="warning";    
            } else {
                resultVariant ="success";
            }

        } else {
            resultVariant ="warning";
            resultVisible=true;
            resultText= 'Ett fel uppstod!';
        }
    }
    catch (e) {
        resultVariant ="warning";
        resultVisible=true;
        resultText= 'Ett fel uppstod!!';
    }
    finally {

    }
    return { resultVariant, resultVisible, resultText, jsondata }; 
}

export function Loading() {

    let [loadingText, setLoadingText] = useState("Laddar");
    let [color, setColor] = useState(process.env.REACT_APP_BrandColor);

    return (
      <ContentWrapper>
          <Container>
              <Sida >
              <Rubrik>{loadingText}</Rubrik>
              <div className="Spinner">
                <ClipLoader color={process.env.REACT_APP_BrandColor}  size={30} /><br></br>
              </div>
          </Sida>
          </Container>
      </ContentWrapper>
    );  
};

export function SpinnerX() {
    let [color, setColor] = useState(process.env.REACT_APP_BrandColor);
    
    return (
    <div className="Spinner">
        <ClipLoader color={process.env.REACT_APP_BrandColor}  size={30} /><br></br>
    </div>
    )
};

export function Header(event) {
    console.log(event.children);    
    return (
    <div className="Header">
        <div style={{float:'left'}}>
            <Rubrik>{event.Title}</Rubrik>
        </div>
        <div style={{float:'right'}}>
            {event.children}
        </div>
        
    </div>
    )
};