import React from 'react';
import { Container, Button} from 'react-bootstrap';

class Popup extends React.Component{
    render() {
      return (
        <div style={{display: this.props.show ? 'block' : 'none', border: '2px dotted #222', backgroundColor: '#ccc',  marginTop: '10px'  }}  >
            <div style={{ margin: '10px'  }}>
                <h4>{this.props.caption}</h4>
                {this.props.children}<br></br>
                {this.props.show}
            </div>
        </div>
      );
    }
  }

  export class CloudPopup extends React.Component {
    
    constructor(props) {
      super(props);
      this.state = {
        showPopup: false,
        showClose: false
      };

      console.log(props); 
      console.log(this); 
    }

    togglePopup() {
      this.setState({showPopup: !this.state.showPopup});
    }

    hidePopup() {
        this.setState({showPopup: false});
    }

    componentDidMount() {
        if (this.props.showByDefault==="true")
            this.setState({showPopup: true});

        if (this.props.showClose==="true")
            this.setState({showClose: true});
    }

    render() {
      return (
        <span className='PopupButton' >          
          
            <Button onClick={this.togglePopup.bind(this)} className={this.props.className} variant={this.props.variant} type="button">{this.props.caption}</Button>&nbsp;
            
            <div className='cloudModal' style={{display: this.state.showPopup ? 'block' : 'none', border: '2px dotted #222' }}  >
                
                <div style={{  paddingLeft:'10px', paddingTop:"6px", paddingBottom:"4px", borderBottom: '2px dotted #222'  }}>
                    <h4>{this.props.caption}</h4>
                </div>                

                <div style={{ margin: '10px'  }}>
                    {this.props.children}

                    <div style={{display: this.state.showClose ? 'block' : 'none'}}>
                        <Button onClick={this.hidePopup.bind(this)} className={this.props.className} variant={this.props.variant} type="button">Close</Button>
                    </div>
                </div>

            </div>
        </span>
      );

    };
}