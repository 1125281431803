import React, { Component, useState } from "react";

export function DateTimeToLocal(isoDate)  {
    var d = new Date(isoDate);
    return d.toLocaleDateString() + " " + d.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'});
}

export function MultiselectGetOptions(rawData) {
    var options = new Array();
    rawData.forEach(element => {
        options.push({ value: element.id, label: element.name });
    });
    return options;
}

export function MultiselectGetDefaultValues(rawData, selectedValues){
    var SelectedValues = [];
    rawData.forEach(Data => {
        if (selectedValues.includes(Data.id)) SelectedValues.push({ value: Data.id, label: Data.name });
    });
    return SelectedValues;
}

export function escapedNewLineToLineBreakTag(string){
    return string.split("\\n").join("\n");
}

export function ConcatLocalDateAndTime2ISO(LocalDateString, LocalTimeString){
    try {
        var LocalDateString = LocalDateString+"T"+LocalTimeString;
        var d = new Date(LocalDateString)
        return d.toISOString();
    } catch {
        var d = new Date();
        return d.toISOString();
    }
} 

export function SplitoutLocalDateFromISO(isoDate){
    var d = new Date(isoDate);
    return d.toLocaleDateString();
}  

export function SplitoutLocalTimeFromISO(isoDate){
    if (isoDate.length <= 8 ) isoDate = '1900-01-01T'+isoDate+"Z";
    var d = new Date(isoDate);
    return d.toLocaleTimeString([],  {hour: '2-digit', minute:'2-digit'});
}



export function getOptions(object){
    return object.map((state) => {
        return <option key={state.Id} value={state.Id}>{state.Name}</option>;
    });
}    