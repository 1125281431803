import { Container, Button,Form, Alert,Table} from 'react-bootstrap';
import {Sida,Rubrik,ContentWrapper,Stycke,StyckeCompact} from "./ContentStyles";
import SpinnerX from './SpinnerX';
import {Pageheight} from "../index";
import React from 'react';
import GlobalCSS from "../App.css";
import useBackend from '../services/useBackend';

export default function MyComponent() {
    const {resultVariant, resultVisible, resultText, loading, data} =  useBackend('/backend/contacts');

    if (!loading) {
        return (
            <ContentWrapper>
                <Container>
                    <Sida style={{minHeight:Pageheight(400)}}>
                    <Rubrik>Crm (Contacts)</Rubrik>
                    <StyckeCompact>
                        <Table  responsive="sm" class={GlobalCSS.gridtable}>
                            <thead>
                                <tr>    <td>Namn</td><td>Subunit</td><td>Zip/City</td> </tr>
                            </thead>
                            <tbody>
                                {data.contacts.map((item) => {
                                    return <tr><td>{item.name}</td> <td>{item.subunit}</td> <td>{item.zip} {item.city}</td> </tr>
                                })}
                            </tbody>
                        </Table>
                    </StyckeCompact>  

                    <Form >
                        <Form.Group className="mb-3"  hidden={!loading}>
                            <SpinnerX  />
                        </Form.Group>                                      
                            
                        <Form.Group className="mb-3"  hidden={!resultVisible}>
                            <Alert show={resultVisible} variant={resultVariant}  className="FormSpace">{resultText}</Alert> 
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Button className="CloudButton" variant="primary" type="submit">New product</Button>
                        </Form.Group>                                
                    </Form>                 
                </Sida>
                </Container>
            </ContentWrapper>
        );
    } else {
        return (
            <ContentWrapper>
                <Container>
                    <Sida style={{minHeight:Pageheight(400)}}>
                        <Rubrik>Loading Crm (Contacts)</Rubrik>
                        <SpinnerX  />
                    </Sida>
                </Container>
            </ContentWrapper>
        );        
    }    
};