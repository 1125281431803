import { Container, Button ,Form, Alert} from 'react-bootstrap';
import {Sida,Rubrik,ContentWrapper} from "../ContentStyles";
import SpinnerX from '../SpinnerX';

import React, { useState, useEffect } from 'react';

const Contact = () => {

    const [resultText, setResultText] = useState("N/A");
    const [resultVariant, setResultVariant] = useState("success");
    const [resultVisible, setResultVisible] = useState(false);
    const [spinnerHidden, setSpinnerHidden] = useState(true);

    const [size, setSize] = useState({
        x: window.innerWidth,
        y: (window.innerHeight-500)
      });
    
      const updateSize = () => {
        setSize({
          x: window.innerWidth,
          y: (window.innerHeight-500)
        });
      }
      useEffect(() => (window.onresize = updateSize), []);  
      useEffect(() => (window.onscroll = updateSize), []);       

    function handleSubmit(e) {
        e.preventDefault();

        setSpinnerHidden(false);
        setResultVisible(true);

        const formData = new FormData(e.target),
        formDataObj = Object.fromEntries(formData.entries())

        var PostData = {SystemID:"Q", FormData : formDataObj};
        
        // https://www.qmenow.com/backend/forms/contact
        fetch('/backend/forms/contact', {
            method: 'POST',
            headers: {
               'Content-Type': 'application/json'
            },
            
            //referrerPolicy: "unsafe-url", // no-referrer-when-downgrade, no-referrer, origin, same-origin...
            //mode: "cors", // same-origin, no-cors

            body: JSON.stringify(PostData),
         }).then(response => response.json())
         .then(data => {
            if (data.errorsException===false) {
                setResultVariant("success");      
                setResultText("Skickat");        
                setSpinnerHidden(true);
                setResultVisible(true);
            } else {
                setResultVariant("warning");
                setResultText(data.resultText);
                setSpinnerHidden(true);
                setResultVisible(true);
            }
         })
         .catch(error => {
            setResultVariant("warning");
            setResultText("Ett fel uppstod!");
            setSpinnerHidden(true);
            setResultVisible(true);
          });
    }

    return (
        <ContentWrapper>
            <Container>
            <Sida style={{minHeight:size.y}}>
                <Rubrik>Kontakt</Rubrik>
                <Form onSubmit={handleSubmit}>

                    <Form.Group className="mb-3" >
                            <Form.Control type="text" name ="Name" placeholder="Namn"  />
                    </Form.Group>
                    
                    <Form.Group className="mb-3" >
                            <Form.Control type="email" name="Email" placeholder="Email" />
                    </Form.Group>

                    <Form.Group className="mb-3" >
                            <Form.Control type="text" name="Subject" placeholder="Ämne" />
                    </Form.Group>

                    <Form.Group className="mb-3" >
                            <Form.Control as="textarea" rows="3" name="Text"  placeholder='Fråga'/>
                    </Form.Group>

                    <Form.Group className="mb-3"  hidden={spinnerHidden}>
                        <SpinnerX  />
                    </Form.Group>                                      
                        
                    <Form.Group className="mb-3"  hidden={!resultVisible}>
                        <Alert show={resultVisible} variant={resultVariant}  className="FormSpace">{resultText}</Alert> 
                    </Form.Group>        

                    <Form.Group className="mb-3">
                        <Button className="QmeButton" variant="primary" type="submit">Skicka</Button>
                    </Form.Group>   

                </Form> 
            </Sida>
            </Container>
        </ContentWrapper>
    );
};

export default Contact;