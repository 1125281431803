import { Container, Button,Form, Alert,Table} from 'react-bootstrap';


import {Sida,Rubrik,ContentWrapper,Stycke,StyckeCompact} from "./ContentStyles";
import SpinnerX from './SpinnerX';
import {Pageheight} from "../index";
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FaFilePdf, FaCheck, FaEuroSign, FaShare, FaEdit} from 'react-icons/fa';
import { EditText, EditTextarea } from 'react-edit-text';
import {CloudPopup} from './common/PopUpX';
import ReactSlider from "react-slider";


//import DragScaleBar from './components/DragScaleBar';

class MyComponent extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
          data: null,
          isLoading: false,
          error: null,
          resultVariant :"success",
          resultVisible:false,
          resultText :'N/A',
          spinnerHidden:false,
          itemGUID:null,
          worklistItem:null
        }
        this.PopUpDeleteClickHandler            = React.createRef();        
    }

    CheckAccessToken;

    handleSubmit = (e) =>{
        e.preventDefault();
        this.setState({ resultVariant :'warning', resultVisible:true, resultText: 'You need elevated permissions to create new service!', spinnerHidden:true  });
    }     

    componentDidMount() {
        this.getData(window.location.pathname.replace('/worklist/',''));
    }

    getData = (ItemGUID) =>{
        fetch('/backend/worklist/'+ItemGUID, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'accessToken':localStorage.getItem('access_token'),
        },
        }).then(response => response.json())
        .then(data => {
            if (data.errorsException===false) {
                this.setState({ resultVariant :'success', resultVisible:false, resultText: data.resultText, spinnerHidden:true, worklistItem:data.worklistItem, itemGUID : ItemGUID });
            } else {
                this.setState({ resultVariant :'warning', resultVisible:true, resultText: data.resultText, spinnerHidden:true  });
            }
        })
        .catch(error => {
            this.setState({ resultVariant :'warning', resultVisible:true, resultText: 'Ett fel uppstod!', spinnerHidden:true  });
        });
    }

    saveData = (formDataObj) =>{
        //e.preventDefault();

        this.setState({ resultVariant :'warning', resultVisible:false, resultText: '', spinnerHidden:false  });
        var PostData = {SystemID:"C", WorkListData : formDataObj};          
        
        fetch('/backend/worklist/'+this.state.itemGUID, {
                method: 'PUT',
                headers: {
                'Content-Type': 'application/json',
                'accessToken':localStorage.getItem('access_token'),
            },
            body: JSON.stringify(PostData),
            }).then(response => response.json())
            .then(data => {
                if (data.errorsException===false) {
                    this.setState({ resultVariant :'success', resultVisible:true, resultText: data.resultText, spinnerHidden:true  });
                } else {
                    this.setState({ resultVariant :'warning', resultVisible:true, resultText: data.resultText, spinnerHidden:true  });
                }
            })
            .catch(error => {
    
            this.setState({ resultVariant :'warning', resultVisible:true, resultText: 'Ett fel uppstod!', spinnerHidden:true  });
        });
    }  
    
    handleSave = ({ name, value, previousValue }) => {
        var formDataObj = {[name] : value};
        this.saveData(formDataObj);
    };

    handleDelete = (e) => {
        e.preventDefault();
        var formDataObj = {Active : false};
        this.saveData(formDataObj);
        this.PopUpDeleteClickHandler.current.hidePopup();        
        window.location.assign("/worklist");

    };    

    handleClosePopup = (e) =>{
        e.preventDefault();
        this.PopUpDeleteClickHandler.current.hidePopup();

    }       

    render() {
        if (this.state.worklistItem !== null) {
            return (
                <ContentWrapper>
                    <Container>
                        <Sida style={{minHeight:Pageheight(400)}}>
                        <Rubrik>Worklist Item</Rubrik>
                        
                        <div style={{marginTop:"10px"}}>
                            <h5>TagLine</h5>
                            <EditText     name="tagLine" style={{fontSize: 14, border: '1px dashed #999', marginTop:'6px', padding: '6px', backgroundColor: "#fff"}} onSave={this.handleSave} defaultValue={this.state.worklistItem.tagLine} />
                        </div>

                        <div style={{marginTop:"10px", marginBottom:"20px"}}>
                        <h5>Text</h5>
                        <EditTextarea name="text"    style={{fontSize: 14,border: '1px dashed #999', marginTop:'6px', padding: '6px', backgroundColor: "#fff", width:"100%"}} onSave={this.handleSave} defaultValue={this.state.worklistItem.text} rows="12"/>
                        </div>

                        <div style={{marginTop:"10px", marginBottom:"20px"}}>
                        <h5>Priority</h5>
                        <EditText     name="setpriority" style={{fontSize: 14, border: '1px dashed #999', marginTop:'6px', padding: '6px', backgroundColor: "#fff"}} onSave={this.handleSave} defaultValue={this.state.worklistItem.priority} />                            
                        </div>

                        <Form onSubmit={this.handleSubmit}>
                            <Form.Group className="mb-3"  hidden={this.state.spinnerHidden}>
                                <SpinnerX  />
                            </Form.Group>                     

                            <Form.Group className="mb-3"  hidden={!this.state.resultVisible}>
                                <Alert show={this.state.resultVisible} variant={this.state.resultVariant}  className="FormSpace">{this.state.resultText}</Alert> 
                            </Form.Group>        

                            <CloudPopup ref={this.PopUpDeleteClickHandler} caption="Delete" className="CloudButton" variant="primary" showByDefault="false" showClose="false">
                                Do you want to delete item?<br></br>
                                <Form.Group className="mb-3">
                                    <Button className="CloudButton" variant="primary" type="button" onClick={this.handleDelete}>Delete</Button>{' '}
                                    <Button className="CloudButton" variant="primary" type="button" onClick={this.handleClosePopup}>Cancel</Button>
                                </Form.Group>         
                            </CloudPopup>                            
                        </Form>  
                    </Sida>
                    </Container>
                </ContentWrapper>
            );
        } else {
            return (
                <ContentWrapper>
                    <Container>
                        <Sida style={{minHeight:Pageheight(400)}}>
                        <Rubrik>Loading....</Rubrik>
                        <SpinnerX  />
                    </Sida>
                    </Container>
                </ContentWrapper>
            );        
        }
    }
}

export default MyComponent;