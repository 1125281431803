import { Container, Button,Form, Alert,Table} from 'react-bootstrap';
import {Sida,Rubrik,ContentWrapper,Stycke,StyckeCompact} from "./ContentStyles";
import SpinnerX from './SpinnerX';
import {Pageheight} from "../index";
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FaFilePdf, FaCheck, FaEuroSign, FaShare, FaEdit} from 'react-icons/fa';
import useBackend from '../services/useBackend';

export default function MyComponent() {
    const {resultVariant, resultVisible, resultText, loading, data} =  useBackend('/backend/cloudServices');    

    const handleSubmit = (e) =>{
        e.preventDefault();
        this.setState({ resultVariant :'warning', resultVisible:true, resultText: 'You need elevated permissions to create new service!', spinnerHidden:true  });
    }     

    const CRUDEditClickHandler = (event)  =>  {
        const ItemGUID = event.currentTarget.getAttribute("ItemGUID");
        window.location.assign("/CloudService/"+ItemGUID);
    }    

    if (!loading) {
        return (
            <ContentWrapper>
                <Container>
                    <Sida style={{minHeight:Pageheight(400)}}>
                    <Rubrik>CloudServices</Rubrik>
                    <StyckeCompact>
                        <Table  responsive="sm" class="gridtable">
                            <thead>
                                <tr>    <td>Produkt</td><td>Tag</td><td className="d-lg-block d-none">BaseURL</td><td>Instances</td><td>Actions</td>    </tr>
                            </thead>
                            <tbody>
                                {data.cloudServices.map((item) => {
                                    
                                    return <tr><td><a href={item.baseURL} target="_blank">{item.description}</a></td> <td>{item.tag}</td> <td className="d-lg-block d-none"><a href={item.baseURL} target="_blank">{item.baseURL}</a></td> <td>{item.instanceCount}</td> <td><FaEdit ItemGUID={item.guid} onClick={CRUDEditClickHandler} /></td>  </tr>
                                })}
                            </tbody>
                        </Table>
                    </StyckeCompact>  

                    <Form onSubmit={handleSubmit}>

                        <Form.Group className="mb-3"  hidden={!loading}>
                            <SpinnerX  />
                        </Form.Group>                                      
                            
                        <Form.Group className="mb-3"  hidden={!resultVisible}>
                            <Alert show={resultVisible} variant={resultVariant}  className="FormSpace">{resultText}</Alert> 
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Button className="CloudButton" variant="primary" type="submit">Create CloudService</Button>
                        </Form.Group>                                
                    </Form>                 
                </Sida>
                </Container>
            </ContentWrapper>
        );
    } else {
        return (
            <ContentWrapper>
                <Container>
                    <Sida style={{minHeight:Pageheight(400)}}>
                    <Rubrik>Loading CloudServices</Rubrik>
                    <SpinnerX  />
                </Sida>
                </Container>
            </ContentWrapper>
        );        
    }
}