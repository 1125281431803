import { Container, Button,Form, Alert,Table} from 'react-bootstrap';
import {Sida,Rubrik,ContentWrapper,Stycke,StyckeCompact} from "./ContentStyles";
import SpinnerX from './SpinnerX';
import {Pageheight} from "../index";
import React from 'react';
import { FaFilePdf, FaCheck, FaEuroSign, FaShare, FaEdit} from 'react-icons/fa';

import {CloudPopup} from './common/PopUpX';

class MyComponent extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
          data: null,
          isLoading: false,
          error: null,
          resultVariant :"success",
          resultVisible:false,
          resultText :'N/A',
          spinnerHidden:false,
          worklist:null,
          modalIsOpen:false
        }

        this.PopUpCreateItem = React.createRef();
    }

    CheckAccessToken;

    componentDidMount() {
        this.getData();
    }

    getData = () =>{
        fetch('/backend/worklist', {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'accessToken':localStorage.getItem('access_token'),
        },
        }).then(response => response.json())
        .then(data => {
            if (data.errorsException===false) {
                this.setState({ resultVariant :'success', resultVisible:false, resultText: data.resultText, spinnerHidden:true, worklist:data.worklist });

            } else {
                this.setState({ resultVariant :'warning', resultVisible:true, resultText: data.resultText, spinnerHidden:true  });
            }
        })
        .catch(error => {
            this.setState({ resultVariant :'warning', resultVisible:true, resultText: 'Ett fel uppstod!', spinnerHidden:true  });
        });
    }

    addData = (formDataObj) =>{
        //e.preventDefault();

        this.setState({ resultVariant :'warning', resultVisible:false, resultText: '', spinnerHidden:false  });
        var PostData = {SystemID:"C", WorkListData : formDataObj};          
        
        fetch('/backend/worklist/', {
                method: 'POST',
                headers: {
                'Content-Type': 'application/json',
                'accessToken':localStorage.getItem('access_token'),
            },
            body: JSON.stringify(PostData),
            }).then(response => response.json())
            .then(data => {
                if (data.errorsException===false) {
                    this.setState({ resultVariant :'success', resultVisible:true, resultText: data.resultText, spinnerHidden:true, worklist:null  });
                    this.getData();
                } else {
                    this.setState({ resultVariant :'warning', resultVisible:true, resultText: data.resultText, spinnerHidden:true  });
                }
            })
            .catch(error => {
    
            this.setState({ resultVariant :'warning', resultVisible:true, resultText: 'Ett fel uppstod!', spinnerHidden:true  });
        });
    }     

    CRUDEditClickHandler = (event)  =>  {
        const ItemGUID = event.currentTarget.getAttribute("ItemGUID");
        window.location.assign("/worklist/"+ItemGUID);
    }    

    render() {
        if (this.state.worklist !== null) {
            return (
                <ContentWrapper>
                    <Container>
                        <Sida style={{minHeight:Pageheight(400)}}>
                        <Rubrik>Worklist</Rubrik>
                        <StyckeCompact>
                            <Table  responsive="sm" class="gridtable">
                                <thead>
                                    <tr>    <td className="d-lg-block d-none">Created</td><td>Deadline</td><td>Priority</td><td>TagLine</td><td>Actions</td>    </tr>
                                </thead>
                                <tbody>
                                    {this.state.worklist.map((item) => {
                                         
                                        return <tr><td className="d-xl-block d-none">{item.created}</td>  <td>{item.deadline}</td> <td>{item.priority}</td> <td>{item.tagLine}</td>   <td><FaEdit ItemGUID={item.guid} onClick={this.CRUDEditClickHandler} /></td>   </tr>
                                    })}
                                </tbody>
                            </Table>
                        </StyckeCompact>  

                        <Form onSubmit={this.handleSubmit}>
                            <Form.Group className="mb-3"  hidden={this.state.spinnerHidden}>
                                <SpinnerX  />
                            </Form.Group>                                      
                                
                            <Form.Group className="mb-3"  hidden={!this.state.resultVisible}>
                                <Alert show={this.state.resultVisible} variant={this.state.resultVariant}  className="FormSpace">{this.state.resultText}</Alert> 
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <CloudPopup ref={this.PopUpCreateItem} caption="Create Item" className="CloudButton" variant="primary" showByDefault="false" showClose="false">
                                    
                                        <Form.Group className="mb-3">
                                            <Form.Label className="FormHeaderText">TagLine</Form.Label>
                                            <Form.Control autoFocus type="text" name="TagLine" />
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Button className="CloudButton" variant="primary" type="submit">Create</Button>&nbsp;
                                            <Button className="CloudButton" variant="primary" type="button" onClick={this.handleClosePopup}>Cancel</Button>
                                        </Form.Group>         
                                    
                                </CloudPopup>
                            </Form.Group>                                
                        </Form>                 
                    </Sida>
                    </Container>
                </ContentWrapper>
            );
        } else {
            return (
                <ContentWrapper>
                    <Container>
                        <Sida style={{minHeight:Pageheight(400)}}>
                        <Rubrik>Loading Worklist</Rubrik>
                        <SpinnerX  />
                    </Sida>
                    </Container>
                </ContentWrapper>
            );        
        }
    }

    handleClosePopup = (e) =>{
        e.preventDefault();
        this.PopUpCreateItem.current.hidePopup();
    }       

    handleSubmit = (e) =>{
        e.preventDefault();

        const formData = new FormData(e.target);
        const formDataObj = Object.fromEntries(formData.entries());

        this.addData(formDataObj);
        e.target.reset() ;
    }         
}

export default MyComponent;