import { Button} from 'react-bootstrap';
import ReactDOM from "react-dom";
import React, { useState, component } from 'react';
import { BrowserRouter, Routes, Route, useParams } from "react-router-dom";
import { ContentDiv } from "./pages/ContentStyles";
import CookieConsent from "./pages/CookieConsent";
import GlobalCSS from "./App.css";
import Menu from "./pages/Menu";


import Footer   from './pages/Footer';
import Home     from "./pages/Home";

import Article  from "./pages/Article2";

import Login                  from "./pages/common/Login";
import MyPage                 from "./pages/common/MyPage";
import Debug                  from "./pages/common/Debug";
import WorkList               from "./pages/WorkList";
import WorkListDetails        from "./pages/WorkListDetails";
import Crm                    from "./pages/Crm";
import NoPage                 from "./pages/common/NoPage";
import Contact                from "./pages/common/Contact"
import Invoicing              from "./pages/Invoicing";
import Timelog                from "./pages/Timelog";
import InvoiceDetails         from "./pages/InvoiceDetails";
import Subscriptions          from "./pages/Subscriptions";
import Products               from "./pages/Products";
import CloudServices          from "./pages/CloudServices";
import CloudServiceDetails    from "./pages/CloudServiceDetails";



import ChangePassword         from "./pages/common/ChangePassword";
import RequestNewPassword     from "./pages/common/RequestNewPassword";
import RequestNewPasswordSet  from "./pages/common/RequestNewPasswordSet";

import FormsInit              from "./pages/forms/init";
import KrinovaNewUser         from "./pages/forms/KrinovaNewUser";


import 'bootstrap/dist/css/bootstrap.min.css';

function Test(){
  const { ArticleGUID } = useParams();
  return <Article ArticleGUID={ArticleGUID}/>
}

export   function XLogOff() {
    localStorage.setItem('access_token', "");
    localStorage.setItem('isLoggedIn', true);
    window.location.href = "/login";
  }
  
  export function Pageheight(offset) {
    //const [size, setSize] = useState({x: window.innerWidth,y: (window.innerHeight-500)});
    
   //return 900;
    
    // const updateSize = () => {
    //   setSize({x: window.innerWidth,y: (window.innerHeight-500)});
    // }
  
    return window.innerHeight-offset;
  
    // useEffect(() => (window.onresize = updateSize), []);  
    // useEffect(() => (window.onscroll = updateSize), []);
  }
  
  export function GetAccessToken() {
    var access_token = localStorage.getItem('access_token');
  
    return access_token;
  }
  
  export function CheckAccessToken() {
    var access_token = localStorage.getItem('access_token');

      var isInlogged = false;

      fetch('/backend/auth/token/isValid/'+access_token, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
      }).then(response => response.json())
      .then(data => {
         if (data.isValid===false) {
          window.location.href = "/login";
        }
      });
  }
  
  export function CheckIfInlogged() {
    var access_token = localStorage.getItem('access_token');
    var isInlogged = false;
  
    if (!access_token || access_token.trim() === "" || (access_token.trim()).length === 0) {
      isInlogged = false;
    } else {

      isInlogged = true;

  
    }
    return isInlogged;
  }

  export function XMenu() {
    return (
      <div className="FormSpace">
        <Button variant="outline-success" href="/password/change">Byt lösenord</Button>&nbsp;
        <Button variant="outline-success" href="/subscriptions">Mina tjänster</Button>&nbsp;
        <Button variant="outline-success" href="/invoices">Mina fakturor</Button>&nbsp;
        <Button variant="outline-success" href="/mypage">Mina sidor</Button>&nbsp;
        <Button variant="outline-success" onClick={XLogOff}>Logga ut</Button>&nbsp;
      </div>
    );
  }  

  export default function App() {
    return (
      <div>
        <Menu />

        <ContentDiv>
          <BrowserRouter>
              <Routes>
              <Route index                               element={<Home />} />

              <Route path="contact"                      element={<Contact />} />
              <Route path="article/:ArticleGUID"         element={<Test/>} />

              <Route path="crm"                          element={<Crm />} />
              <Route path="Products"                     element={<Products />} />
              <Route path="CloudServices"                element={<CloudServices />} />
              <Route path="CloudService/:ItemGUID"       element={<CloudServiceDetails />} />

              <Route path="WorkList"                     element={<WorkList />} />
              <Route path="worklist/:ItemGUID"           element={<WorkListDetails />} />

              <Route path="cms"                          element={<Debug />} />    
              <Route path="Timelog"                          element={<Timelog />} />   
              <Route path="invoicing"                    element={<Invoicing />} />    
              <Route path="invoicing/details/:GUID"      element={<InvoiceDetails />} />    
              <Route path="subscriptions"                element={<Subscriptions />} />    
              
                      

              <Route path="Password/Request/new"         element={<RequestNewPassword />} />
              <Route path="Password/Request/set/:GUID"   element={<RequestNewPasswordSet />} />
              <Route path="Password/change"              element={<ChangePassword />} />

              <Route path="contact"                      element={<Contact />} />   
              <Route path="login"                        element={<Login />} />      
              <Route path="MyPage"                       element={<MyPage />} /> 
              <Route path="Debug"                        element={<Debug />} />    
              <Route path="*"                            element={<NoPage />} />              

              <Route path="Forms"                        element={<FormsInit />} />    
              <Route path="Forms/d38b5104-1d25-4158-ab0a-45563ce41c3a"                        element={<KrinovaNewUser />} />    

              </Routes>
          </BrowserRouter>
        </ContentDiv>
        <Footer />
        <CookieConsent />
    </div>
    );
}

ReactDOM.render(<App />, document.getElementById("root"));

