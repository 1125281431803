import React from "react";
import {
Box,
Container,
Row,
Column,
FooterLink,
Heading,
Address,
SocialMediaBlock
} from "./FooterStyles";

const Footer = () => {
return (
	<Box>
	<Container>
		<Row>
		<Column>
			<Heading>Company</Heading>
			<Address>
						CloudSolution Nordic AB<br />
						SE559213875301
			</Address>
		</Column>
		<Column>
			<Heading>Address</Heading>
			<Address>
					Stridsvagnsvägen 14<br />
					SE-291 39 Kristianstad <br />
					Sweden
			</Address>
		</Column>
		<Column>
			<Heading>Contact</Heading>
			<Address>
					Email: info@cloudsolution.se <br />
					Phone: +46 (0)702 37 68 86
			</Address>
		</Column>
		<Column>
			<Heading>Social Media</Heading>
			<SocialMediaBlock>
				<a href="https://www.facebook.com/CloudSolution-Nordic-AB-106665090715320/" target="_facebook"> <img src="/images/icon-facebook.png" /></a>&nbsp;&nbsp;
				<a href="https://www.linkedin.com/company/cloudsolution-nordic/"            target="_linkedin"> <img src="/images/icon-linkedin.png" /></a>
			</SocialMediaBlock>
		</Column>
		</Row>
	</Container>
	</Box>
);
};
export default Footer;
