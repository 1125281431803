import React  from 'react';
import {Sida,Rubrik,Stycke} from "./ContentStyles";
import SpinnerX from './SpinnerX';


const baseURL = "https://www.cloudsolution.se/backend/wwwContent/news";

function CreateNewsDom(item){
    document.getElementById('DomNews').innerHTML += '<div style="margin-bottom:40px;">';
    document.getElementById('DomNews').innerHTML += '<p class="ContentDate">'+item.created+'</p>';
    document.getElementById('DomNews').innerHTML += '<p class="ContentSubject" >'+item.subject+'</p>';
    if (item.content!=='') document.getElementById('DomNews').innerHTML += '<p  class="ContentSubject">'+item.content+'</p>';
    document.getElementById('DomNews').innerHTML += '</div>';
}

export default class News extends React.Component  {

    constructor(props) {
        super(props);
        this.state = {
        data : null
        };
    }

    componentDidMount() {
        this.populateFaq();
        this.setState({});   
    }

    populateFaq= () => {
        fetch(baseURL)
        .then((response) => response.json())
        .then((findresponse)=>{
            this.state.data= findresponse.News;
            this.setState({});
        })        
    }

    render(){
        if (this.state.data === null) {
            return (          <div>
                <Rubrik>News</Rubrik>
                <div id ="DomNews"></div>
                <SpinnerX /><br></br>
                </div>
            );
        }   
        else {
            return(
                <div>
                
                <Rubrik>News</Rubrik>
                <div id ="DomNews" className='ContentWrapper' />
                {
                    this.state.data.map((item) => (
                        CreateNewsDom(item)
                    ))
                }
                </div>
            );
        };
    }
}