import React, {useState,useEffect} from "react";
import { Outlet, Link } from "react-router-dom";
import { Container, Button, Navbar, Nav, NavItem, NavDropdown, MenuItem, NavLink,FormControl ,Form} from 'react-bootstrap';
import { createBrowserHistory } from 'history';
import './Menu.css';
import {XLogOff, Pageheight, CheckIfInlogged, XMenu, GetAccessToken} from "../index";

const history = createBrowserHistory();

const Menu = () => {

  	const [menuIndex, setMenuIndex] = useState(false);

  	const setMenuStyle = () => {
		if (window.scrollY == 0 && window.location.pathname == "/") {
			setMenuIndex(true);
		} else {
			setMenuIndex(false);
		}  
	}

  	useEffect(() => (window.onscroll = setMenuStyle), []);
  	useEffect(() => (window.onload = setMenuStyle), []);
  
  	return (
      	<div className={menuIndex ? 'NavBarIndex' : 'NavBar'}>
          	<Navbar collapseOnSelect expand="lg" bg="dark_" variant="dark">
            <Container>
				<Navbar.Brand href="/" >
					<img 
					alt=""
					src="/images/logo.png" 
					width_="100px" 
					height="40px" 
					className="d-inline-block align-top"/>{' '}

				</Navbar.Brand>

              	<Navbar.Toggle aria-controls="responsive-navbar-nav" />
              	<Navbar.Collapse id="responsive-navbar-nav" id_="Test">

					<Nav className="me-auto" id="Test"> 


					{CheckIfInlogged() ? ( 
						<>
						<Nav.Link style={{color:"white"}} href="/mypage">Min Profil</Nav.Link>
						<Nav.Link style={{color:"white"}} href="/crm">CRM</Nav.Link>
						<Nav.Link style={{color:"white"}} href="/CloudServices">CloudServices</Nav.Link>
						<Nav.Link style={{color:"white"}} href="/Products">Products</Nav.Link>
						<Nav.Link style={{color:"white"}} href="/subscriptions">Subscriptions</Nav.Link>
						<Nav.Link style={{color:"white"}} href="/timelog">Timelog</Nav.Link>
						<Nav.Link style={{color:"white"}} href="/invoicing">Invoicing</Nav.Link>
						
						<Nav.Link style={{color:"white"}} href="/worklist">WorkList</Nav.Link>
						</>
					) : (
						<>
						<Nav.Link href="/">Home</Nav.Link>
						<Nav.Link href="/#DomNews">News</Nav.Link>
						<Nav.Link href="/#DomArticles">Articles</Nav.Link>
						<Nav.Link href="/contact">Contact</Nav.Link>					
						</>
					)}    
					</Nav>

					{!CheckIfInlogged() ? ( 
					<div>
						<Nav>
						<Button className="CloudLoginButton_" variant="outline-secondary" href="/login">Logga in</Button>                     
						</Nav>
					</div>             
					) : ( 
					<div>
						<Nav>
						<Button className="CloudLoginOutButton"  variant="success" onClick={XLogOff}>Logga ut</Button>&nbsp;
						</Nav>                    
					</div>             
					)}     

              	</Navbar.Collapse>
            </Container>
          	</Navbar>
        	<Outlet />
    	</div>
  	)
};

export default Menu;