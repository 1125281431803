import { Container, Button,Form, Alert,Table} from 'react-bootstrap';
import {Sida,Rubrik,ContentWrapper,Stycke,StyckeCompact} from "./ContentStyles";
import {Pageheight} from "../index";
import React from 'react';
import { FaFilePdf, FaCheck, FaEuroSign, FaShare, FaEdit} from 'react-icons/fa';
import GlobalCSS from "../App.css";
import useBackend, {CallAPI, Loading, SpinnerX, Check, Header} from '../services/useBackend';
import {SplitoutLocalDateFromISO} from '../services/misc';

export default function MyComponent() {
    const {resultVariant, resultVisible, resultText, loading, data} =  useBackend('/backend/subscriptions');    

    const handleSubmit = (e) =>{
        e.preventDefault();    
        this.setState({ resultVariant :'warning', resultVisible:true, resultText: 'Function not implemented yet!', spinnerHidden:true  });
    }     


    if (!loading) {
        return (
            <ContentWrapper>
                <Container>
                    <Sida style={{minHeight:Pageheight(400)}}>                    
                    <Header Title="Subscriptions">Total amount: {data.totalAmountMonth}/month</Header>
                    <StyckeCompact>
                        <Table  responsive="sm"  class="gridtable">
                            <thead>
                                <tr>    <td>Product</td><td>Customer</td><td>Qty</td><td>Price</td><td className="d-lg-block d-none">Binding</td><td className="d-lg-block d-none">State</td>   </tr>
                            </thead>
                            <tbody>
                                {data.subscriptions.filter(S=>S.active==true).map((item) => {
                                    return <tr> <td>{item.productName}</td> <td>{item.instance}</td> <td>{item.qty}</td> <td>{item.productPrice}</td> <td className="d-lg-block d-none">{SplitoutLocalDateFromISO(item.subscriptionBindDate)}</td> <td className="d-lg-block d-none">{item.subscriptionState}</td>
                                        <td><FaEdit /> </td>
                                        </tr>
                                })}
                            </tbody>
                        </Table>
                    </StyckeCompact>  

                    <Form onSubmit={handleSubmit}>

                        <Form.Group className="mb-3"  hidden={!loading}>
                            <SpinnerX  />
                        </Form.Group>                                      
                            
                        <Form.Group className="mb-3"  hidden={!resultVisible}>
                            <Alert show={resultVisible} variant={resultVariant}  className="FormSpace">{resultText}</Alert> 
                        </Form.Group>                            
                    </Form>                
                </Sida>
                </Container>
            </ContentWrapper>
        );
    } else {
        return (
            <ContentWrapper>
                <Container>
                    <Sida style={{minHeight:Pageheight(400)}}>
                    <Rubrik>Loading Subscriptions</Rubrik>
                    <SpinnerX  />
                </Sida>
                </Container>
            </ContentWrapper>
        );        
    }

}