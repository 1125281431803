import { Container, Button,Form, Alert} from 'react-bootstrap';
import {Sida,Rubrik,ContentWrapper} from "../ContentStyles";
import SpinnerX from '../SpinnerX';

import {Pageheight, CheckAccessToken, XMenu, GetAccessToken} from "../../index";

import React from 'react';

class MyComponent extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
          data: null,
          isLoading: false,
          error: null,
          count:0,
          resultText :'Hej',
          resultVariant :"success",
          resultVisible:false,
          formDataCity:''        
        }
    }

    componentDidMount() {
        //CheckAccessToken;
    }

    doSomething= () => {
        this.setState(
            { count: 0, resultText:"Something is done!", resultVisible:true, resultVariant :"success" }
        );
    }    

    nolla= () => {
        this.setState(
            { count: 0, resultText:'Nollat', resultVisible:true, resultVariant :"warning" }
        );
    }    
    
    increment = () => {
        this.setState(
          prevState => ({ count: prevState.count + 1 })
        );
      };    

    render() {
    return (
        <ContentWrapper>
            <Container>
            <Sida style={{minHeight:Pageheight(400)}}>
                <Rubrik>Debug</Rubrik>
                <p>{this.state.count}</p>
                <Alert show={this.state.resultVisible} variant={this.state.resultVariant}  className="FormSpace">{this.state.resultText}</Alert> 

                <div className="FormSpace">
                    <Button variant="outline-success" onClick={this.doSomething}>Do Something</Button>&nbsp;
                    <Button variant="outline-success" onClick={this.increment}>Increment</Button>&nbsp;
                    <Button variant="outline-success" onClick={this.nolla}>Zero</Button>&nbsp;
                </div>
                
                {CheckAccessToken() ? ( 
                    <div>
                        <XMenu />
                    </div>             
                ) : ( 
                    <div>
                    </div>             
                )}
                <Form.Label className="FormHeaderText">Access_token: {GetAccessToken()}</Form.Label><br></br>
                <Form.Label className="FormHeaderText">Pageheight: {Pageheight(0)}</Form.Label>

            </Sida>
            </Container>
        </ContentWrapper>
    );
  }
}

export default MyComponent;