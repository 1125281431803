import { Container} from 'react-bootstrap';
import NewsItems from './NewsItems';
import ArticleItems from './ArticleItems';
import Top      from "./Top2";
import React from 'react';


import {Sida,Rubrik,Stycke, Artickel, ContentWrapper} from "./ContentStyles";

const Home = () => {
  return (
    <div>
    <Top />
    <ContentWrapper>
    <Container>
    <Sida>
      <Artickel id="DomStart">
          
          <Rubrik>What's up!</Rubrik>
          <Stycke>
            Be patient cause it's yet to early to tell you the whole story.<br /><br />

            Right now we are working fulltime inhouse developing SaaS services for your data.<br />
            If you are intrested in cloud based products for digital archive sign up for our newsletter.<br />
          </Stycke>
      </Artickel>
      
      <NewsItems />
      <ArticleItems />
    </Sida>    
    </Container>
    </ContentWrapper>
    </div>
  );
};

export default Home;