import { Container} from 'react-bootstrap';
import {Sida,Rubrik,Stycke,ContentWrapper} from "./ContentStyles";
import SpinnerX from './SpinnerX';

import React, { useState, useEffect } from 'react';

const Contact = () => {
    const [size, setSize] = useState({
        x: window.innerWidth,
        y: (window.innerHeight-200)
      });
    
      const updateSize = () => {
        setSize({
          x: window.innerWidth,
          y: (window.innerHeight-200)
        });
      }
      useEffect(() => (window.onresize = updateSize), []);  
      useEffect(() => (window.onscroll = updateSize), []);      

      const [content, setContent] = useState({
        subject: '{Subject}',
        text: '{Text}'
      });
    
      function populateFaq() {
        var url = "https://www.cloudsolution.se/backend/wwwContent/articles/"+window.location.pathname.replace('/article/','');
      
        fetch(url).then((response) => response.json())
        .then((findresponse)=>{

            setContent({subject: findresponse.Article.subject});
            document.getElementById('DomArticlesContent').innerHTML = findresponse.Article.content;
            //document.getElementById('DomArticlesSubject').innerHTML = findresponse.Article.subject;

            this.setState({});
            document.getElementById('DomArticlesContent').innerHTML = findresponse.Article.content;
        },(error) => {
        })        
      }

      useEffect(() => (window.onload = populateFaq), []);  

    return (
            <ContentWrapper>
            <Container>
            <Sida style={{minHeight:size.y}}>
          
                <Rubrik id="DomArticlesSubject">{content.subject}</Rubrik>
                <Stycke id="DomArticlesContent"><SpinnerX></SpinnerX></Stycke>
            </Sida>
            </Container>
            </ContentWrapper>

    );
};

export default Contact;