import { useState, useEffect } from "react";
import { Container, Nav, Button  } from 'react-bootstrap';
import { createBrowserHistory } from 'history';
import './Top.css';
import React from 'react';
import GlobalCSS from "../App.css";

const history = createBrowserHistory();

const Top = () => {

  const [size, setSize] = useState({
    x: window.innerWidth,
    y: window.innerHeight
  });

  const updateSize = () => {
    setSize({
      x: window.innerWidth,
      y: window.innerHeight
    });
  }
  useEffect(() => (window.onresize = updateSize), []);

  return (
    <div>
        <div style={{height: size.y}} className="TopBackgrountWrapper"> 
            <div style={{height: size.y}} className="TopInnerWrapper"> 
                <Container>
                    <div className="App-content">
                        <p>We love cloudy days!</p>
                    </div> 
                    <div className="App-content2">
                        <p>Care about your data 24/7/365 wherever in the world you go :)</p>
                    </div>                     
                </Container>

            </div>
        </div>
        <div  className="TopScrollButton" onClick={()=> window.location.href='/#DomStart'}>

        <img src="https://www.cloudsolution.se/images/down02.gif" style={{height: 70}}/>

        </div>
    </div>
  )
};

export default Top;