import React from 'react';
import {Sida,Rubrik,Stycke} from "./ContentStyles";
import SpinnerX from './SpinnerX';

const baseURL = "https://www.cloudsolution.se/backend/wwwContent/articles";

function CreateArticleDom(item){
    document.getElementById('DomArticles').innerHTML += '<div style="margin-bottom:40px;">';
    document.getElementById('DomArticles').innerHTML += '<p class="ContentDate">'+item.created+'</p>';
    document.getElementById('DomArticles').innerHTML += '<p class="ContentSubject" ><a href="./article/'+item.GUID+'">'+item.subject+'</a></p>';
    //if (item.content!='') document.getElementById('DomArticles').innerHTML += '<p  class="ContentSubject">'+item.content+'</p>';
    document.getElementById('DomArticles').innerHTML += '</div>';
}

export default class Articles extends React.Component  {

    constructor(props) {
        super(props);
        this.state = {
        data : null
        };
    }

    componentDidMount() {
        this.populateFaq();   
        this.setState({});
    }

    populateFaq= () => {
        fetch(baseURL)
        .then((response) => response.json())
        .then((findresponse)=>{
            this.state.data= findresponse.Articles;
            this.setState({});
        })        
    }

    render(){
        if (this.state.data === null) {
            return (
                <div id="" className='ContentWrapper'>
                <Rubrik>Articles</Rubrik>
                <div id ="DomArticles"></div>
                <SpinnerX /><br></br>
                </div>
            );
        }
        else {
            return(
                <div id="" >
                <Rubrik>Articles</Rubrik>
                
                <div id ="DomArticles" className='ContentWrapper' />
                {
                    this.state.data.map((item) => (
                        CreateArticleDom(item)
                    ))
                }

                </div>
            );
        };
    }
}