import { Container, Button,Form, Alert,Table} from 'react-bootstrap';
import {Sida,Rubrik,ContentWrapper,Stycke,StyckeCompact} from "./ContentStyles";
import SpinnerX from './SpinnerX';
import {Pageheight} from "../index";
import React from 'react';
import { FaFilePdf, FaCheck, FaEuroSign, FaShare, FaEdit} from 'react-icons/fa';
import {CloudPopup} from './common/PopUpX';

class MyComponent extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
          data: null,
          isLoading: false,
          error: null,
          resultVariant :"success",
          resultVisible:false,
          resultText :'N/A',
          spinnerHidden:false,
          invoiceInfo:null
        }
        this.PopUpSendReminder            = React.createRef();
        this.PopUpResetDatesClickHandler  = React.createRef();
        this.PopUpMarkAsPayedClickHandler = React.createRef();
        this.PopUpMarkAsSentClickHandler  = React.createRef();
        this.PopUpSendInvoice             = React.createRef();
    }

    CheckAccessToken;  

    componentDidMount() {
        this.getData(window.location.pathname.replace('/invoicing/details/',''));
    }

    MarkAsPayedClickHandler = (event)  =>  {
        event.preventDefault();   
        const InvoiceGUID = event.currentTarget.getAttribute("InvoiceGUID")
        console.log("MarkAsPayedClickHandler: "+ InvoiceGUID);
        var formDataObj = {Payed : true};
        this.saveData(InvoiceGUID,formDataObj);
        this.getData(window.location.pathname.replace('/invoicing/details/',''));
        this.PopUpMarkAsPayedClickHandler.current.hidePopup();
        //this.setState({ resultVariant :'warning', resultVisible:true, resultText: "MarkAsPayedClickHandler!", spinnerHidden:true  });        
    }

    MarkAsSentClickHandler = (event)  =>  {
        event.preventDefault();   
        const InvoiceGUID = event.currentTarget.getAttribute("InvoiceGUID")
        console.log("MarkAsSentClickHandler: "+ InvoiceGUID);
        var formDataObj = {Sent : true};
        this.saveData(InvoiceGUID,formDataObj);
        this.getData(window.location.pathname.replace('/invoicing/details/',''));
        this.PopUpMarkAsSentClickHandler.current.hidePopup();
        //this.setState({ resultVariant :'warning', resultVisible:true, resultText: "MarkAsSentClickHandler!", spinnerHidden:true  });
    }

    SendInvoiceHandle = (event)  =>  {
        event.preventDefault();   
        const InvoiceGUID = event.currentTarget.getAttribute("InvoiceGUID")
        console.log("SendInvoiceHandle: "+ InvoiceGUID);
        var formDataObj = {Send : true};
        this.saveData(InvoiceGUID,formDataObj);
        this.getData(window.location.pathname.replace('/invoicing/details/',''));
        this.PopUpSendInvoice.current.hidePopup();
        //this.setState({ resultVariant :'warning', resultVisible:true, resultText: "MarkAsSentClickHandler!", spinnerHidden:true  });
    }    
    
    ResetDatesClickHandler = (event)  =>  {
        event.preventDefault();   
        const InvoiceGUID = event.currentTarget.getAttribute("InvoiceGUID")
        console.log("ResetDatesClickHandler: "+ InvoiceGUID);
        var formDataObj = {ResetDates : true};
        this.saveData(InvoiceGUID,formDataObj);
        this.getData(window.location.pathname.replace('/invoicing/details/',''));
        this.PopUpResetDatesClickHandler.current.hidePopup();
        //this.setState({ resultVariant :'warning', resultVisible:true, resultText: "ResetDatesClickHandler!", spinnerHidden:true  });
    }

    SendReminderHandle = (event) =>{
        event.preventDefault();
        const InvoiceGUID = event.currentTarget.getAttribute("InvoiceGUID")
        console.log("SendReminderHandle: "+ InvoiceGUID);        
        var formDataObj = {Reminder : true};
        this.saveData(InvoiceGUID,formDataObj);
        this.getData(window.location.pathname.replace('/invoicing/details/',''));
        this.PopUpSendReminder.current.hidePopup();
        //this.setState({ resultVariant :'warning', resultVisible:true, resultText: "Reminder sent!", spinnerHidden:true  });
    }     

    handleClosePopup = (e) =>{
        e.preventDefault();
        this.PopUpSendReminder.current.hidePopup();
        this.PopUpResetDatesClickHandler.current.hidePopup();
        this.PopUpMarkAsPayedClickHandler.current.hidePopup();
        this.PopUpMarkAsSentClickHandler.current.hidePopup();
        this.PopUpSendInvoice.current.hidePopup();
    }     
    
    OpenPDF = (event) => {
        event.preventDefault();       
        const InvoiceGUID = event.currentTarget.getAttribute("InvoiceGUID")
        window.open("/backend/invoices/"+InvoiceGUID, '_blank').focus();

    }

    getData = (InvoiceGUID) =>{
        fetch('/backend/invoices/detail/'+InvoiceGUID, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'accessToken':localStorage.getItem('access_token'),
        },
        }).then(response => response.json())
        .then(data => {
            if (data.errorsException===false) {
                this.setState({ spinnerHidden:true, invoiceInfo:data.invoiceInfo });
            } else {
                this.setState({ resultVariant :'warning', resultVisible:true, resultText: data.resultText, spinnerHidden:true  });
            }
        })
        .catch(error => {
            this.setState({ resultVariant :'warning', resultVisible:true, resultText: 'Ett fel uppstod!', spinnerHidden:true  });
            //this.setState({   });
        });
    }  
    
    saveData = (InvoiceGUID,formDataObj) =>{
        //e.preventDefault();

        this.setState({ resultVariant :'warning', resultVisible:false, resultText: '', spinnerHidden:false  });
        var PostData = {SystemID:"C", InvoiceData : formDataObj};          
        
        fetch('/backend/invoices/'+InvoiceGUID, {
                method: 'PUT',
                headers: {
                'Content-Type': 'application/json',
                'accessToken':localStorage.getItem('access_token'),
            },
            body: JSON.stringify(PostData),
            }).then(response => response.json())
            .then(data => {
                if (data.errorsException===false) {
                    this.setState({ resultVariant :'success', resultVisible:true, resultText: data.resultText, spinnerHidden:true  });
                } else {
                    this.setState({ resultVariant :'warning', resultVisible:true, resultText: data.resultText, spinnerHidden:true  });
                }
            })
            .catch(error => {
    
            this.setState({ resultVariant :'warning', resultVisible:true, resultText: 'Ett fel uppstod!', spinnerHidden:true  });
        });
    }      

    render() {
        if (this.state.invoiceInfo !== null) {
            return (
                <ContentWrapper>
                    <Container>
                        <Sida style={{minHeight:Pageheight(400)}}>
                        <Rubrik>Invoice: {this.state.invoiceInfo.identifier}</Rubrik>
                        <StyckeCompact>
                        <Table  responsive="sm"  class="gridtable">
                                <thead>
                                    <tr>    <td>Identity</td><td className="d-lg-block d-none">Created</td><td>Customer</td><td className="d-lg-block d-none">Int. Comment</td><td>Unpaid</td> <td>Sent</td>  </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{this.state.invoiceInfo.identifier}</td> 
                                        <td className="d-lg-block d-none">{this.state.invoiceInfo.created}</td> 
                                        <td><small>{this.state.invoiceInfo.customer}</small></td>  
                                        <td className="d-lg-block d-none"><small>{this.state.invoiceInfo.internalComment}</small></td> 
                                        <td>{this.state.invoiceInfo.amount}</td>
                                        <td><FaCheck hidden={!this.state.invoiceInfo.sent}/></td>
                                    </tr>
                                </tbody>
                            </Table>                            
                        </StyckeCompact>  

  
                            <Form.Group className="mb-3"  hidden={this.state.spinnerHidden}>
                                <SpinnerX  />
                            </Form.Group>                                      
                                
                            <Form.Group className="mb-3"  hidden={!this.state.resultVisible}>
                                <Alert show={this.state.resultVisible} variant={this.state.resultVariant}  className="FormSpace">{this.state.resultText}</Alert> 
                            </Form.Group>

<div>

                            <CloudPopup ref={this.PopUpSendInvoice} caption="Send Invoice" className="CloudButton" variant="primary" showByDefault="false" showClose="false">
                                    Do you want to send?<br></br>
                                    <Form.Group className="mb-3">
                                        <Button className="CloudButton" variant="primary" type="button" onClick={this.SendInvoiceHandle} InvoiceGUID={this.state.invoiceInfo.guid}>Yes</Button>&nbsp;
                                        <Button className="CloudButton" variant="primary" type="button" onClick={this.handleClosePopup}>Cancel</Button>
                                    </Form.Group>         
                            </CloudPopup>

                            <CloudPopup ref={this.PopUpSendReminder} caption="Send Reminder" className="CloudButton" variant="primary" showByDefault="false" showClose="false">
                                    Do you want to send a reminder?<br></br>
                                    <Form.Group className="mb-3">
                                        <Button className="CloudButton" variant="primary" type="button" onClick={this.SendReminderHandle} InvoiceGUID={this.state.invoiceInfo.guid}>Yes</Button>&nbsp;
                                        <Button className="CloudButton" variant="primary" type="button" onClick={this.handleClosePopup}>Cancel</Button>
                                    </Form.Group>         
                            </CloudPopup>

                            <CloudPopup ref={this.PopUpMarkAsPayedClickHandler} caption="Mark as payed" className="CloudButton" variant="primary" showByDefault="false" showClose="false">
                                Do you want to set state to payed?<br></br>
                                <Form.Group className="mb-3">
                                    <Button className="CloudButton" variant="primary" type="button" onClick={this.MarkAsPayedClickHandler} InvoiceGUID={this.state.invoiceInfo.guid}>Yes</Button>&nbsp;
                                    <Button className="CloudButton" variant="primary" type="button" onClick={this.handleClosePopup}>Cancel</Button>
                                </Form.Group>         
                            </CloudPopup>

                            <CloudPopup ref={this.PopUpResetDatesClickHandler} caption="Reset dates" className="CloudButton" variant="primary" showByDefault="false" showClose="false">
                                Do you want to reset dates?<br></br>
                                <Form.Group className="mb-3">
                                    <Button className="CloudButton" variant="primary" type="button" onClick={this.ResetDatesClickHandler} InvoiceGUID={this.state.invoiceInfo.guid}>Yes</Button>&nbsp;
                                    <Button className="CloudButton" variant="primary" type="button" onClick={this.handleClosePopup}>Cancel</Button>
                                </Form.Group>         
                            </CloudPopup>    

                            <CloudPopup ref={this.PopUpMarkAsSentClickHandler} caption="Mark as sent" className="CloudButton" variant="primary" showByDefault="false" showClose="false">
                                Do you want to set state sent?<br></br>
                                <Form.Group className="mb-3">
                                    <Button className="CloudButton" variant="primary" type="button" onClick={this.MarkAsSentClickHandler} InvoiceGUID={this.state.invoiceInfo.guid}>Yes</Button>&nbsp;
                                    <Button className="CloudButton" variant="primary" type="button" onClick={this.handleClosePopup}>Cancel</Button>
                                </Form.Group>         
                            </CloudPopup>

                            <Button onClick={this.OpenPDF} className="CloudButton" variant="primary" type="button"  InvoiceGUID={this.state.invoiceInfo.guid}>Visa PDF</Button>&nbsp;

                                                                                       
                            
                            </div>           
                    </Sida>
                    </Container>
                </ContentWrapper>
            );
        } else {
            return (
                <ContentWrapper>
                    <Container>
                        <Sida style={{minHeight:Pageheight(400)}}>
                        <Rubrik>Loading Invoice data</Rubrik>
                        <SpinnerX  />
                    </Sida>
                    </Container>
                </ContentWrapper>
            );        
        }
    }
}

export default MyComponent;