import { Container, Button,Form, Alert,Table} from 'react-bootstrap';
import {Sida,Rubrik,ContentWrapper,Stycke,StyckeCompact} from "./ContentStyles";
import SpinnerX from './SpinnerX';
import {Pageheight} from "../index";
import React from 'react';
import useBackend from '../services/useBackend';

export default function MyComponent() {
    const {resultVariant, resultVisible, resultText, loading, data} =  useBackend('/backend/products');

    const handleSubmit = (e) =>{
        e.preventDefault();    
        this.setState({ resultVariant :'warning', resultVisible:true, resultText: 'Function not implemented yet!', spinnerHidden:true  });
    }     

    if (!loading) {
        return (
            <ContentWrapper>
                <Container>
                    <Sida style={{minHeight:Pageheight(400)}}>
                    <Rubrik>Products</Rubrik>
                    <StyckeCompact>
                        <Table  responsive="sm">
                            <thead>
                                <tr>    <td>Produkt</td><td className="d-lg-block d-none">Description</td><td>Artnr</td><td>Price</td>    </tr>
                            </thead>
                            <tbody>
                                {data.products.map((item) => {
                                    return <tr><td>{item.name}</td> <td className="d-lg-block d-none">{item.description}</td> <td>{item.artnr}({item.id})</td> <td>{item.price}</td></tr>
                                })}
                            </tbody>
                        </Table>
                    </StyckeCompact>  

                    <Form onSubmit={handleSubmit}>

                        <Form.Group className="mb-3"  hidden={!loading}>
                            <SpinnerX  />
                        </Form.Group>                                      
                            
                        <Form.Group className="mb-3"  hidden={!resultVisible}>
                            <Alert show={resultVisible} variant={resultVariant}  className="FormSpace">{resultText}</Alert> 
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Button className="CloudButton" variant="primary" type="submit">New product</Button>
                        </Form.Group>                                
                    </Form>                 
                </Sida>
                </Container>
            </ContentWrapper>
        );
    } else {
        return (
            <ContentWrapper>
                <Container>
                    <Sida style={{minHeight:Pageheight(400)}}>
                    <Rubrik>Loading Products</Rubrik>
                    <SpinnerX  />
                </Sida>
                </Container>
            </ContentWrapper>
        );        
    }
}