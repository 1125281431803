import { Container, Button ,Form, Alert} from 'react-bootstrap';

import NewsItems from '../NewsItems';
import ArticleItems from '../ArticleItems';
import Top      from "../Top2";
import SpinnerX from '../SpinnerX';


import React, { useState, useEffect } from 'react';

import {Sida,Rubrik,Stycke, Artickel, ContentWrapper} from "../ContentStyles";

const FormsInit = () => {

	const [resultText, setResultText] = useState("N/A");
	const [resultVariant, setResultVariant] = useState("success");
	const [resultVisible, setResultVisible] = useState(false);
	const [spinnerHidden, setSpinnerHidden] = useState(true);

	const [size, setSize] = useState({
		x: window.innerWidth,
		y: (window.innerHeight-500)
		});

		const updateSize = () => {
		setSize({
			x: window.innerWidth,
			y: (window.innerHeight-500)
		});
	}
	
	useEffect(() => (window.onresize = updateSize), []);  
	useEffect(() => (window.onscroll = updateSize), []);     

	function handleSubmit(e) {
		e.preventDefault();
	
		setSpinnerHidden(false);
		setResultVisible(true);
	
		const formData = new FormData(e.target),
		formDataObj = Object.fromEntries(formData.entries())
	
		var PostData = {SystemID:"C", FormData : formDataObj};
		
		fetch('/backend/forms/KrinovaNewUser', {
			method: 'POST',
			headers: {
			   'Content-Type': 'application/json',
			   'accessToken':localStorage.getItem('access_token'),
			},
	
			body: JSON.stringify(PostData),
		 }).then(response => response.json())
		 .then(data => {
			if (data.errorsException===false) {
				setResultVariant("success");      
				setResultText(data.resultText);        
				setSpinnerHidden(true);
				setResultVisible(true);
				document.getElementById("98432").reset();
				
			} else {
				setResultVariant("warning");
				setResultText(data.resultText);
				setSpinnerHidden(true);
				setResultVisible(true);
			}
		 })
		 .catch(error => {
			setResultVariant("warning");
			setResultText("Ett fel uppstod!");
			setSpinnerHidden(true);
			setResultVisible(true);
		  });
	} 	

   return (
    <div>
    <ContentWrapper>
    <Container>
    <Sida>
		<Artickel id="DomStart">
			<Rubrik>Registrering av ny användare Krinova AB!</Rubrik>
			<Form  onSubmit={handleSubmit} id="98432">

				<Form.Group className="mb-3" >
				<Form.Label className="FormHeaderText">Uppgifter</Form.Label>
					<Form.Control type="text" name ="Name1" placeholder="Förnamn"  />
				</Form.Group>

				<Form.Group className="mb-3" >
					<Form.Control type="text" name="Name2" placeholder="Efternamn" />
				</Form.Group>

				<Form.Group className="mb-3" >
					<Form.Control as="textarea" rows="3" name="Info"  placeholder='Övriga upplysningar'/>
				</Form.Group>				

				<Form.Group className="mb-3" >
					<Form.Control type="email" name="Email" placeholder="Email" />
				</Form.Group>	

				<Form.Group className="mb-3" >
					<Form.Control type="text" name="Phone" placeholder="Telefon" />
				</Form.Group>					

				<Form.Group className="mb-3" >
					<Form.Label className="FormHeaderText">Önskar åtkomst till</Form.Label>
					<Form.Check type="checkbox" name="AccessWiFi"  label="Åtkomst WiFi i Krinovas lokaler" />
				</Form.Group>

				<Form.Group className="mb-3" >
					<Form.Check type="checkbox" name="AccessGemensamt" label="Åtkomst till mappar för Gemensamt" />
				</Form.Group>				

				<Form.Group className="mb-3" >
					<Form.Check type="checkbox" name="AccessEkonomi"  label="Åtkomst till mappar för Ekonomi" />
				</Form.Group>

				<Form.Group className="mb-3" >
					<Form.Check type="checkbox" name="AccessFastighet"  label="Åtkomst till mappar för Fastighet" />
				</Form.Group>				

				<Form.Group className="mb-3" >
					<Form.Check type="checkbox" name="AccessReception"  label="Åtkomst till mappar för Reception" />
				</Form.Group>

				<Form.Group className="mb-3" >
					<Form.Check type="checkbox" name="AccessBusinessdesigners"  label="Åtkomst till mappar för Businessdesigners" />
				</Form.Group>													

				<Form.Group className="mb-3" >
					<Form.Check type="checkbox" name="AccessVPN"  label="Extern åtkomst till Krinovas nätverk via VPN" />
				</Form.Group>								

				<Form.Group className="mb-3">
					<Form.Label className="FormHeaderText">Lösenord</Form.Label>
					<Form.Control type="password" name="password"  placeholder='Minst 8 tecken, bokstäver + siffror'/>
				</Form.Group>

				<Form.Group className="mb-3">
					<Form.Control type="password" name="password_validate"  placeholder='Upprepa lösenord' />
				</Form.Group>    				

				<Form.Group className="mb-3"  hidden={spinnerHidden}>
					<SpinnerX  />
				</Form.Group>                                      
					
				<Form.Group className="mb-3"  hidden={!resultVisible}>
					<Alert show={resultVisible} variant={resultVariant}  className="FormSpace">{resultText}</Alert> 
				</Form.Group>        

				<Form.Group className="mb-3">
					<Button className="QmeButton" variant="primary" type="submit">Skicka</Button>
				</Form.Group>   

				<Form.Group className="mb-3">
					<Form.Label className="FormHeaderText"><small>Din ansökan kommer att behandlas efter att din organisation (Krinova AB) godkännt din beställning!</small></Form.Label>
				</Form.Group>

			</Form> 
		</Artickel>
    </Sida>    
    </Container>
    </ContentWrapper>
    </div>
  );
};

export default FormsInit;