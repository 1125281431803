import ClipLoader from "react-spinners/GridLoader";
// https://www.davidhu.io/react-spinners/
import React, { useState } from 'react';

const SpinnerX = () => {

  let [color, setColor] = useState("#ccc");
  
    return (
    <div><br></br>
    <ClipLoader color={color}  size={30} />
    </div>
    )
  };
  
  export default SpinnerX;